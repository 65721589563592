import React, { useState } from 'react'
import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'


import './Footer.scss'

function Footer() {
  const [formData, setFormData] = useState({name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData; 

  const handleChangeInput = (e) => {
    const { name, value } = e.target

    setFormData({...formData, [name]: value})
  }
   const handleSubmit = () => { 
     setLoading(true)

     const contact = { 
       _type: 'contact', 
       name: name, 
       email: email, 
       message: message
     }

     client.create(contact)
      .then(() => {
        setLoading(false); 
        setIsFormSubmitted(true)
      })
   }
  
  return (
    <>
      <h2 className='head-text'> Want to reach out?</h2>
      <p className='head-sentence'>Got a question or a proposal, ask for my resume or just want to say hi? I'm always up for a chat! </p>

      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.email} alt='email'/>
          <a href='mailto:akramihone@gmail.com' className='p-text'>akramihone@gmail.com</a>
        </div>

      {!isFormSubmitted ? 
        <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input className='p-text' type='text' placeholder='Your name' onChange={handleChangeInput} name='name' value={name}/>
          </div>
          <div className='app__flex'>
            <input className='p-text' type='email' placeholder='Your email' onChange={handleChangeInput} name='email' value={email}/>
          </div>
          <div>
            <textarea
              className='p-text'
              placeholder='Your message'
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send Message'}</button>
        </div>
        : <div>
          <h3 className='head-text'>Thank you for getting in touch!</h3>
        </div>
      }
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'), 
  'contact', 
  'primarybg'
)