import React from 'react'; 
import { BsTwitter, BsLinkedin } from 'react-icons/bs'
import { AiFillGithub } from 'react-icons/ai'; 


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div> 
          <a href='https://www.linkedin.com/in/aramihone/' target='_blank' rel="noopener noreferrer">
            <BsLinkedin />
          </a>
        </div>
        <div> 
          <a href='https://github.com/AnjaRL' target='_blank' rel="noopener noreferrer">
            <AiFillGithub/> 
          </a>
          
        </div>
        <div>
          <a href='https://twitter.com/AnjaInTech' target='_blank' rel="noopener noreferrer">
            <BsTwitter/>
          </a> 
        </div>
    </div>
  )
}

export default SocialMedia;