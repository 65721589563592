import React, { useState, useEffect} from 'react'

import { AppWrap, MotionWrap } from '../../wrapper'
import './About.scss'
import { client } from '../../client'

const About = () => {
  const [abouts, setAbouts] = useState([]); 
  
  useEffect(() => {
    const query = '*[_type == "abouts"]'; 

    client.fetch(query)
      .then((data) =>  setAbouts(data))
  }, [])
  
  return (
    <>
      <h2 className='head-text'>About me</h2>

      <div className='about__presentation'>
        <p className='about__hey'>Hi Again! </p>
        <span className='about__header'>
        As a front-end developer, I have a strong curiosity and attention to detail. I am always looking to learn new skills and tackle tough challenges, with a particular focus on user experience. My goal is to create easy-to-use interfaces and write efficient code. In my career, I find great satisfaction in learning, creating, and finding ways to improve things.
          <br/>
          In my spare time, you can find me exploring the underwater world with my fins and mask, or participating in water sports such as kitesurfing, wakeboarding, and paddling. 
          <p className='about__paragraph1'>
          Prior to my current career path, I worked as a senior financial auditor in Paris, helping tech companies with their financial records and procedures. This experience marked my first steps into the tech industry, and ultimately led me to pursue a full-time full stack development bootcamp at Ironhack.  I am now excited to continue my career journey and seek out opportunities to work on exciting projects. 
            <br/> 
          </p>
          <p className='about__paragraph2'>
          If you think we could build something great together, don't hesitate to reach out to me.
          </p>
        </span>
      </div>
  
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'), 
  'about', 
  'app__whitebg'
)