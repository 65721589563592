import React from 'react'; 
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Header.scss'
import { AppWrap } from '../../wrapper'

const Header = () => (
    <div className='app__header app__flex'>
      <motion.div
      whileInView={{ x : [-100, 0], opacity : [0, 1] }}
      transition={{ duration: 2 }}
      className="app__header-info"
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'> 
            <span> 👋🏾</span>
            <div style={{ marginLeft:15 }}>
              <h1 className='head-text'>Hello, I'm Anja</h1>
              <p className='p-text' style={{fontSize:'1.1rem'}}>Front-end developer crafting useful applications with design</p>
            </div>
          </div>
        </div>

        <div className='app__header-see'> 
          <a href='#work' className='button-see'>
            <span>see my work</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
        </div>
      </motion.div>

      

      <motion.div
        whileInView={{ opacity : [0, 1] }}
        transition={{ duration: 2, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
      </motion.div>
    </div>
)

export default AppWrap(Header, 'home')